#top-sites-container {
  width: 100%; }

#top-sites {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  pointer-events: none; }
  #top-sites.pointer {
    pointer-events: auto; }

#show-more-button {
  width: 100%;
  height: 2.5rem;
  margin-top: 1.8rem;
  margin-left: 2.5rem;
  flex-direction: row;
  display: none;
  pointer-events: auto; }

#top-sites > .topsites:nth-of-type(n+10) {
  display: none; }

#top-sites.show-more > .topsites:nth-of-type(n+10) {
  display: unset; }

#top-sites > #show-more-button:nth-child(n+11) {
  display: flex; }

#show-more-button > [data-icon] {
  width: 2.7rem;
  height: 2.7rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.5rem;
  font-size: 2.5rem;
  text-align: center;
  color: var(--color-gs60);
  pointer-events: none; }

#show-more {
  width: calc(100% - 2.5rem);
  height: 2rem;
  font-family: OpenSans;
  font-size: 1.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2rem;
  letter-spacing: normal;
  color: var(--color-gs60);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.3rem;
  text-transform: uppercase;
  pointer-events: none; }

#top-sites > #show-more-button > #show-more > #show-less-text {
  display: none; }

#top-sites.show-more > #show-more-button > #show-more > #show-more-text {
  display: none; }

#top-sites.show-more > #show-more-button > #show-more > #show-less-text {
  display: inherit; }

.topsites {
  width: 9.6rem;
  height: 11.2rem;
  margin-left: 1.8rem;
  margin-top: 1.7rem; }

.top-site .description {
  width: 9.6rem;
  height: 2rem;
  flex: auto;
  align-self: center;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  overflow: hidden; }

.top-site .title,
.top-site .url {
  font-family: OpenSans;
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-gs100);
  pointer-events: none;
  width: 9.6rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.top-site .favicon {
  width: 8.2rem;
  height: 8.2rem;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  border-radius: 2.2rem;
  pointer-events: none; }

.top-site .favicon img {
  width: 100%;
  height: 100%;
  border-radius: 2.2rem; }
