.search-bar {
  width: 100%;
  height: var(--top-control-height);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .search-bar > kai-searchbar {
    width: calc(100% - 1.5rem - 3.2rem - 1.5rem);
    height: 6rem;
    margin-top: -.8rem; }
  .search-bar.focus > .option-menu-button {
    display: none; }
  .search-bar.focus > kai-searchbar {
    width: calc(100% - 1.5rem); }
  .search-bar > .option-menu-button {
    width: 3.2rem;
    height: 3.2rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1.5rem;
    color: var(--color-gs100);
    font-size: 3.2rem;
    line-height: 3.2rem; }

.active {
  color: var(--theme-color); }
