:root {
  --infogationbar-height: 0rem;
  --tab-bar-height: 5rem;
  --header-height: 8rem;
  --actionbar-height: 7rem;
  --top-control-height: 6rem;
  --fullscreen-urlbar-height: 3rem;
  --browser-window-height: calc(100% - var(--fullscreen-urlbar-height));
  --browser-window-no-focus-view-height: calc(var(--view-height) - var(--top-control-height) - var(--tab-bar-height));
  --view-height: calc(100% - var(--infogationbar-height));
  --max-content-height: calc(var(--view-height) - var(--header-height));
  --max-content-with-tab-height: calc(var(--max-content-height) - var(--tab-bar-height));
  --max-content-with-actionbar-height: calc(var(--max-content-height) - var(--actionbar-height));
  --search-tabs-empty-url: url("/resources/images/img-tabs-empty-light.svg"); }
