/**
 * Disable selection by default except for elements with
 * "contenteditable".
 */
*:not(input) {
  -moz-user-select: none;
  outline: none; }

[contenteditable="true"] {
  -moz-user-select: text; }

html, body, .App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: var(--color-gs00);
  overflow: hidden;
  padding: 0;
  margin: 0; }

.home-view {
  width: 100%;
  min-height: 0;
  flex-grow: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  display: block; }

#promoted-ad {
  width: 30rem;
  height: 24.5rem;
  margin: 3rem 3rem 0 3rem; }

.ad-nav {
  outline: 0; }

.hidden {
  display: none; }
