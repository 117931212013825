.search {
  height: 100%;
  pointer-events: none; }

#search-results, #search-tip {
  width: 100%;
  overflow-y: scroll; }

#search-tip > p {
  width: unset;
  padding: 4rem 2rem;
  text-align: center;
  font-size: 1.8rem;
  color: var(--color-gs100);
  margin: 0; }
