.suggestions-separator, .history-separator {
  display: block; }

.suggestions-separator.disabled {
  display: none; }

.suggestions {
  font-size: 1.6rem;
  overflow-x: hidden; }

.suggestions ul {
  list-style: none;
  white-space: nowrap;
  margin: 0;
  padding-left: 0; }

.suggestions li {
  height: 6rem;
  line-height: 6rem;
  margin: 0;
  padding-left: 2rem;
  padding-right: 1rem;
  width: 100%;
  display: flex;
  background-color: var(--color-gs00);
  color: var(--color-gs100);
  font-family: OpenSans;
  font-size: 1.8rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  pointer-events: auto; }
  .suggestions li > div {
    pointer-events: none; }

.suggestions li:focus {
  background-color: #1CC1C4;
  outline: none;
  color: var(--color-gs00); }

.suggestions li div span.highlight {
  font-weight: 700;
  color: var(--theme-color-l); }

.suggestions li div[data-icon="search"] {
  position: relative;
  font-size: 3.2rem;
  margin-right: 1rem; }

.local .result {
  height: 7rem;
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  padding-right: 1rem;
  position: relative;
  pointer-events: auto; }
  .local .result > div {
    pointer-events: none; }

.local .result:focus {
  background-color: var(--color-cyan);
  outline: none; }
  .local .result:focus .title {
    color: var(--color-gs00); }
  .local .result:focus .meta {
    color: var(--color-gs00); }

.local .result:active {
  background-color: #3e666e; }

.local .icon {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  align-self: center; }
  .local .icon img {
    width: 3.2rem;
    height: 3.2rem; }
  .local .icon.empty {
    border: 1px dashed #5A5A5A;
    border-radius: 2px; }
    .local .icon.empty img {
      opacity: 0; }

.local .meta {
  height: 1.9rem;
  font-family: OpenSans;
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-gs60);
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.local .description {
  overflow: hidden;
  align-self: center;
  margin-left: 1.5rem; }

.local .title {
  height: 2.4rem;
  font-family: OpenSans;
  font-size: 1.8rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-gs100);
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.local .highlight {
  font-weight: 700; }
